:root {
    /* Define color variables */
    --vividblue: #024ee5;
    --black: #000000;
    --desaturateddarkblue: #515977;
    --White: #fff;
    --grayishblue: #b4bcd3;
    --lightgrayishblue: #d9dbe2;
    --lightgrayishbluehome: #f5f6fa;
    --paleblue: #f8faff;
    --darklimegreen: #00ad0d;
    --darkmoderatebluecustom: #44548b1a;
    --lightishgray: #d8deed;
    --darkblue: #041e79;
    --verydarkblue: #001355;
    --darkbluehomecustom: #0331a4;
    --darkbluehome: #00359d;
    --darkbluecustom: #041e790a;
    --darkmoderateblue: #3d4a7a;
    --desaturatedbluecustom: #505977;
    --desaturatedblue: #7a86a5;
    --vividred: #ff3333;
    --moderateviolet: #9847bf;
    --violet: #8c31b7;
    --brightviolet: #c34bfa;
    --paleviolet: #f7e6ff;
    --verypaleblue: #e5ebff;
    --verywhiteblue: #f1f5ff;
    --moderatevioletcustom: #8c32b7;
    --slightlydesaturatedblue: #7582bb;
    --moderatecyan: #32adb3;
    --brightcyan: #3fd8df;
    --palemagenta: #fcf3ff;
    --softviolet: #d280f9;
    --verydarkbluecustom: #0c1529;
    --softblue: #55aade;
    --veryslightlydesaturatedblue: #7483bb;
    --lightgrayishlimegreen: #e0f5e0;
    --vividbluecustom: #034ee5;
    --SecondaryBrightviolet: #c34afa;
    --VeryDarklimegreen: #00960b;
    --Pureorange: #fe7f00;
    --Strongred: #d32f2f;
}
