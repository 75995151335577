.top-header {
    background: transparent
        linear-gradient(110deg, var(--softviolet) 0%, var(--vividbluecustom) 34%, var(--darkblue) 100%);
    opacity: 1;
    height: 120px;
    padding: 16px 24px;
    justify-content: center;
    box-shadow: none !important;
    @media (min-width: 769px) {
        padding: 16px 24px 16px 270px;
    }
    @media (min-width: 1200px) {
        padding: 16px 24px 16px 354px;
    }
    @media (min-width: 1537px) {
        padding: 16px 24px 16px 440px;
    }
    .version-info {
        text-align: right;
        font-size: var(--font-size-12);
        color: var(--White);
        position: relative;
        right: 8px;
        bottom: 8px;
    }
    .more-icon {
        position: absolute;
        left: 0;
        box-shadow: none;
        color: var(--White);
        display: flex;
        justify-content: center;
        top: 126px;
        background: var(--darkblue);
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: inherit;
        padding-left: 4px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        @media (min-width: 1200px) {
            display: none;
        }
        &:hover {
            background-color: var(--verypaleblue);
            color: var(--darkblue);
        }
        svg {
            animation: bounce-horizontal 1s infinite;
            &:hover {
                animation: none;
            }
        }
    }

    .back-info {
        display: flex;
        color: var(--White);
        i {
            font-size: 24px;
            margin-right: 8px;
        }
    }
    @keyframes bounce-horizontal {
        0%,
        100% {
            transform: translatex(-5%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translatex(10%);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
    .breadcrumbs {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .path-info {
            color: var(--White);
            text-align: left;
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            opacity: 0.8;
            font-weight: var(--font-weight-medium);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .main-path {
            font-weight: var(--font-weight-medium);
            font-size: var(--font-size-32);
            color: var(--White);
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .product-logo {
        position: absolute;
        display: none;
        left: 32px;
        width: 184px;
        height: 72px;
        top: 24px;
        @media (min-width: 769px) {
            display: block;
        }
    }

    .action-btn {
        position: absolute;
        height: 36px;
        width: 36px;
        background: var(--White);
        left: 36px;
        font-weight: bold;
        color: var(--darkblue);
        &:hover {
            background: var(--White);
        }
    }
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .copy-btn {
        color: var(--White);
        margin-left: 16px;
        display: none;
        @media (min-width: 900px) {
            display: flex;
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
.menu-info {
    padding: 0 16px;
    border-bottom: 1px solid var(--lightishgray);
    padding-bottom: 12px;
    width: 100%;
    .welcome-info {
        font-weight: 500;
        font-size: var(--font-size-14);
        letter-spacing: 0px;
        color: var(--darkbluehomecustom);
    }
    .user-info {
        color: var(--darkbluehomecustom);
        font-weight: 600;
        font-size: var(--font-size-22);
        letter-spacing: 0px;
        line-height: 22px;
    }
}
.menu-letter {
    min-height: 48px;
    min-width: 48px;
    background: rgba(0, 0, 0, 0) linear-gradient(90deg, var(--SecondaryBrightviolet), var(--vividbluecustom) 122%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: var(--font-size-16);
    font-weight: 500;
    letter-spacing: 0.8px;
    color: var(--White);
}
.web-view {
    display: none;
    @media (min-width: 769px) {
        display: flex;
    }
}
.mobile-view {
    display: flex;

    @media (min-width: 769px) {
        display: none;
    }
}
