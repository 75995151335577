.accordian-content {
    border-radius: 16px !important;
    box-shadow: none !important;
    margin-top: 24px;
    &:before {
        display: none;
    }
    .custom-controllers {
        .MuiFormLabel-root {
            font-size: var(--font-size-14);
            color: var(--desaturatedblue);
        }
        .MuiFormControl-root {
            margin-bottom: 0;
        }
        .MuiInputBase-root.MuiFilledInput-root .MuiFilledInput-input {
            font-size: var(--font-size-14);
            text-transform: capitalize;
            color: var(--black);
        }
    }
    &.Mui-expanded {
        margin-top: 24px !important;
    }
    .accordian-header {
        background: var(--desaturatedbluecustom);
        font-weight: var(--font-weight-semi-bold);
        padding: 0 24px;
        font-size: var(--font-size-18);
        letter-spacing: 0px;
        color: var(--White);
        text-transform: capitalize;
        height: 48px;
        margin: auto;
        border-radius: 16px;
        &.Mui-expanded {
            min-height: 48px;
            border-radius: 16px 16px 0px 0px;
            margin-top: 24px;
        }
        svg {
            color: var(--White);
        }
    }
    .accordian-info {
        border-radius: 0px 0px 16px 16px;
        padding: 16px 24px;
    }
    .custom-divider {
        border: 1px solid var(--lightishgray);
    }
    .chips-box {
        border: 1px solid var(--lightishgray);
        border-radius: 8px;
        width: max-content;
        padding: 6px 12px;
        display: flex;
        margin: 0 8px 8px 0px !important;
        flex-direction: row;
        align-items: center;
        .chips-dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 12px;
            background: var(--darkmoderateblue);
        }
        .chips-txt {
            font-size: var(--font-size-13);
            letter-spacing: 0px;
            color: var(--black);
        }
    }
    .list-info {
        border-radius: 13px;
        padding: 16px 24px;
        .MuiGrid-root {
            margin: 0px;
        }
        .MuiGrid-item {
            padding: 0px;
        }
        &:hover {
            background: none;
        }
        .MuiOutlinedInput-root {
            color: var(--desaturatedblue);
            border-radius: 8px;
            input {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: var(--font-size-14);
                letter-spacing: 0.14px;
                font-weight: var(--font-weight-medium);
            }
        }
        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 12px;
            margin-top: 3px;
            &.good {
                background: var(--darklimegreen);
            }
            &.bad {
                background: var(--vividred);
            }
            &.unknown {
                background: var(--darkblue);
            }
        }
        .MuiListItemIcon-root {
            min-width: auto;
        }

        .MuiInputBase-adornedEnd {
            color: var(--darkblue);
            &.Mui-focusVisible {
                border: none;
            }
            .MuiOutlinedInput-notchedOutline {
                border: 2px solid var(--vividblue);
            }
            .MuiSvgIcon-root {
                color: var(--desaturateddarkblue);
            }
            input {
                text-transform: uppercase;
            }
        }
        &.Mui-selected {
            background: var(--darkbluecustom);
            .string-info {
                border: 1px solid var(--moderateviolet);
            }
            &:hover {
                background: var(--darkbluecustom);
            }
            &.Mui-focusVisible {
                background: var(--darkbluecustom);
            }
        }

        .string-info {
            background: var(--paleviolet);
            font-weight: normal;
            border-radius: 12px;
            font-size: var(--font-size-13);
            letter-spacing: 0px;
            color: var(--violet);
            padding: 4px 12px;
            margin-left: 8px;
        }

        .header-txt {
            font-size: var(--font-size-16);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: 0px;
            margin-bottom: 8px;
        }
        .text-width {
            max-width: 85%;
            @media (min-width: 1537px) {
                max-width: 80%;
            }
        }
        .text-header-width {
            max-width: 80%;
        }
        .required {
            color: var(--vividred);
        }
        .chains {
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            margin-bottom: 8px;
            .blue-txt {
                color: var(--darkblue);
                font-weight: var(--font-weight-medium);
            }
        }
        .sub-content {
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            color: var(--black);
            .bold-txt {
                font-weight: var(--font-weight-semi-bold);
            }
        }
        .error-no {
            font-size: var(--font-size-16);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: 0px;
            color: var(--black);
        }
        .error-info {
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            color: var(--black);
        }
    }
}
