.page-layout {
    &.api-layout {
        padding-right: 36px;
        .api-description {
            background: var(--White);
            padding: 16px 24px;
            border-radius: 16px;
            h2 {
                font-weight: 600;
                font-size: var(--font-size-20);
                letter-spacing: 0px;
                color: var(--darkblue);
                text-transform: capitalize;
                margin-bottom: 12px;
            }
            .description {
                font-size: var(--font-size-14);
                letter-spacing: 0px;
                color: var(--black);
                margin-bottom: 28px;
                p {
                    font-size: var(--font-size-14);
                    letter-spacing: 0px;
                    color: var(--black);
                }
            }
            .code-generator {
                background: #fafafc 0% 0% no-repeat padding-box;
                border: 1px solid var(--grayishblue);
                border-radius: 12px;
                box-shadow: none;

                .MuiCardHeader-root {
                    padding: 8px 16px;
                    border-bottom: 1px solid var(--lightgrayishblue);
                    @media (min-width: 1537px) {
                        padding: 12px 24px;
                    }
                }
                .code-header {
                    font-weight: 500;
                    font-size: var(--font-size-14);
                    letter-spacing: 0px;
                    color: var(--black);
                    text-transform: capitalize;
                }
                .code-content {
                    font-size: var(--font-size-14);
                    letter-spacing: 0px;
                    color: var(--black);
                    word-break: break-word;
                }
                .MuiTypography-root {
                    font-size: var(--font-size-12);
                    font-weight: 600;
                    letter-spacing: 0px;
                    color: var(--black);
                    text-transform: uppercase;
                }
                .copy-btn {
                    width: 88px;
                    height: 32px;
                    border-radius: 8px;
                    font-size: var(--font-size-12);
                    letter-spacing: 0px;
                    font-weight: var(--font-weight-medium);
                    color: var(--desaturateddarkblue);
                    text-transform: capitalize;
                    &.Mui-disabled {
                        color: rgba(0, 0, 0, 0.26);
                        cursor: not-allowed;
                        i {
                            color: rgba(0, 0, 0, 0.26);
                        }
                    }
                    i {
                        font-size: 20px;
                        color: var(--desaturateddarkblue);
                        margin-right: 8px;
                    }
                }
                .MuiCardContent-root {
                    padding: 8px 16px;
                    @media (min-width: 1537px) {
                        padding: 20px 24px;
                    }
                }
                .link-txt {
                    color: var(--moderatevioletcustom);
                    font-size: var(--font-size-14);
                    letter-spacing: 0px;
                    word-break: break-word;
                    white-space: pre-wrap;
                }
            }
        }
    }
}
