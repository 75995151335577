* {
    margin: 0;
}
body {
    margin: 0;
    padding: 0;
    background: var(--White) !important;
    color: var(--black);
}
img {
    max-width: 100%;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
a,
.MuiLink-underlineAlways {
    text-decoration: none !important;
    transition: ease-in-out 0.4s;
}
.btn {
    padding: 16px 36px;
    border-radius: 34px;
    @media (max-width: 1299.98px) {
        padding: 8px 20px;
    }
    @media (max-width: 991.98px) {
        padding: 8px 14px;
    }
    @media (max-width: 767.98px) {
        padding: 8px 20px;
    }
}

.title-main {
    margin-bottom: 60px;
    @media (max-width: 991.98px) {
        margin-bottom: 32px !important;
    }
    @media (max-width: 1399.98px) {
        margin-bottom: 40px;
    }
    @media (max-width: 767.98px) {
        margin-bottom: 30px;
    }
    small {
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 2.4px;
        line-height: 42px;
        @media (max-width: 1399.98px) {
            font-size: var(--font-size-14);
            line-height: 36px;
        }
        &.blue {
            color: var(--vividblue);
        }
        &.purple {
            color: var(--moderatevioletcustom);
        }
    }
}

.title-xl {
    font-size: 44px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 42px;
    @media (max-width: 1399.98px) {
        font-size: 36px;
        line-height: 36px;
    }
    @media (max-width: 767.98px) {
        font-size: var(--font-size-32);
        line-height: 36px;
    }
    @media (max-width: 429.98px) {
        font-size: var(--font-size-26);
        line-height: 32px;
    }
}

.title-lg {
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-semi-bold);
    line-height: 36px;
    @media (max-width: 767.98px) {
        font-size: var(--font-size-22);
        line-height: 28px;
    }
    @media (max-width: 429.98px) {
        font-size: var(--font-size-20);
        line-height: 24px;
    }
}
.title-sm {
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
}

.overflow-hidden {
    height: 100vh;
    overflow: hidden;
}

header {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
    transition: ease-in-out 0.4s;

    @media (max-width: 575.98px) {
        top: 30px;
    }
    @media (max-width: 429.98px) {
        top: 24px;
    }

    &.header-sticky {
        position: fixed;
        top: 0;
        padding: 20px 0;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        background: var(--White);
        -webkit-animation: headerSticky 0.95s ease forwards;
        animation: headerSticky 0.95s ease forwards;

        .nav-elements {
            ul {
                li {
                    a {
                        color: var(--darkbluehomecustom);
                        &:hover,
                        &:focus,
                        &.active {
                            color: var(--moderatecyan);
                        }
                    }
                }
            }
        }

        .menu-icon {
            @media (max-width: 900px) {
                display: flex;
                color: var(--darkbluehomecustom);
            }
        }
    }

    .menu-icon {
        display: none;
        @media (max-width: 900px) {
            display: flex;
            color: var(--White);
        }
        .MuiSvgIcon-root {
            font-size: 30px;
            cursor: pointer;
        }
    }

    .nav-elements {
        @media (max-width: 900px) {
            position: fixed;
            left: -300px;
            top: 0;
            background-color: var(--White);
            width: 300px;
            height: 100vh;
            transition: all 0.3s ease-in;
            overflow: hidden;
            z-index: 1000;
            &.active {
                left: 0;
                .menu-logo {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 24px;
                    border-bottom: 1px solid var(--verypaleblue);
                }
            }
        }
        .menu-logo {
            display: none;
            a {
                display: flex;
                img {
                    width: 150px;
                }
            }
            .MuiSvgIcon-root {
                cursor: pointer;
                color: var(--darkbluehomecustom);
            }
        }
        ul {
            display: flex;
            @media (max-width: 900px) {
                flex-direction: column;
                padding: 20px 24px;
                height: calc(100vh - 140px);
                overflow-y: auto;
            }
            li {
                @media (max-width: 900px) {
                    display: flex;
                    &:first-child {
                        a {
                            padding-top: 10px;
                        }
                    }
                }
                &:not(:first-child) {
                    margin-left: 40px;
                    @media (max-width: 1299.98px) {
                        margin-left: 30px;
                    }
                    @media (max-width: 900px) {
                        margin: 0;
                    }
                }
                a {
                    color: var(--White);
                    font-size: var(--font-size-20);
                    &:hover,
                    &:focus,
                    &.active {
                        color: var(--brightcyan);
                    }
                    @media (max-width: 1299.98px) {
                        font-size: var(--font-size-18);
                    }
                    @media (max-width: 900px) {
                        color: var(--black);
                        width: 100%;
                        font-size: var(--font-size-16);
                        padding: 14px 0;
                        border-bottom: 1px solid var(--White);
                        &:hover,
                        &:focus,
                        &.active {
                            color: var(--moderatecyan);
                        }
                    }
                }
            }
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(2, 78, 229, 0.9);
        z-index: 999;
        display: none;
        cursor: pointer;
        &.overlay-active {
            @media (max-width: 900px) {
                display: block;
            }
        }
    }
}

.header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
        display: flex;
    }

    img {
        @media (max-width: 991.98px) {
            width: 180px;
        }
        @media (max-width: 575.98px) {
            width: 140px;
        }
    }
}

@keyframes headerSticky {
    0% {
        margin-top: -100px;
    }
    100% {
        margin-top: 0;
    }
}

.banner-section {
    min-height: 100vh;
    background: linear-gradient(
            128deg,
            var(--softviolet) 0%,
            var(--vividblue) 15%,
            var(--darkbluehomecustom) 56%,
            var(--darkblue) 78%,
            var(--verydarkblue) 100%
        )
        0% 0% no-repeat padding-box;
    color: var(--White);
    display: flex;
    flex-direction: column;
    @media (max-width: 1199.98px) {
        min-height: auto;
    }
    @media (max-height: 800px) and (min-width: 1199.99px) {
        align-items: flex-end;
        justify-content: flex-end;
    }

    @media (max-width: 991.98px) {
        align-items: flex-end;
        justify-content: flex-end;
        min-height: auto;
    }

    .banner-overlap {
        position: absolute;
        width: 100%;
        bottom: 100px;

        @media (max-height: 800px) and (min-width: 1199.99px), (max-width: 1199.98px) {
            padding: 200px 0 80px 0;
            position: relative;
            bottom: 0;
        }

        @media (max-width: 991.98px) {
            padding: 160px 0 32px 0;
            position: relative;
            bottom: 0;
        }

        @media (max-width: 429.98px) {
            padding: 160px 0 50px 0;
        }
    }

    .banner-content {
        margin-bottom: 48px;
        @media (max-width: 991.98px) {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: 64px;
        line-height: 80px;
        font-weight: var(--font-weight-medium);
        margin-bottom: 60px;
        // max-width: 690px;
        @media (max-width: 1399.98px) {
            font-size: 54px;
            line-height: 60px;
            margin-bottom: 48px;
            //   max-width: 600px;
        }
        @media (max-width: 1299.98px) {
            font-size: 42px;
            line-height: 52px;
            //  max-width: 460px;
        }
        @media (max-width: 991.98px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 36px;
            //   max-width: 330px;
        }
        @media (max-width: 767.98px) {
            //  max-width: 460px;
            font-size: 42px;
            line-height: 52px;
        }
        @media (max-width: 575.98px) {
            max-width: 100%;
            font-size: 40px;
            line-height: 48px;
        }
        @media (max-width: 429.98px) {
            font-size: 36px;
            line-height: 48px;
        }
    }

    p {
        font-size: var(--font-size-20);
        line-height: 30px;
        color: rgba(255, 255, 255, 0.8);
        max-width: 560px;
        margin-bottom: 78px;

        @media (max-width: 1399.98px) {
            margin-bottom: 56px;
        }
        @media (max-width: 1299.98px) {
            font-size: var(--font-size-16);
            line-height: 24px;
            max-width: 420px;
        }
        @media (max-width: 991.98px) {
            max-width: 330px;
            margin-bottom: 44px;
        }
        @media (max-width: 767.98px) {
            max-width: 100%;
            font-size: var(--font-size-18);
            line-height: 26px;
        }
        @media (max-width: 575.98px) {
            font-size: var(--font-size-16);
            line-height: 24px;
        }
    }

    .banner-image {
        margin-right: -100px;
        @media (max-width: 1799.98px) {
            margin-right: 0;
        }
    }
}

.banner-btns {
    display: flex;
    @media (max-width: 399.98px) {
        flex-direction: column;
    }
    a {
        color: var(--White);
        text-decoration: none;
        margin-right: 24px;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-medium);
        border-radius: 16px;
        padding: 8px 32px;
        text-transform: capitalize;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 991.98px) {
            font-size: var(--font-size-15);
            margin-right: 12px;
        }
        @media (max-width: 399.98px) {
            margin: 0;
            align-self: flex-start;
            &:last-child:not(:first-child) {
                margin-top: 20px;
            }
        }
    }
    .btn-pink {
        background: var(--black) linear-gradient(90deg, var(--SecondaryBrightviolet), var(--vividbluecustom) 122%);
    }
    .btn-blue {
        background: rgba(255, 255, 255, 0.1);
        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }
}

.about-section {
    min-height: 100vh;
    background: var(--verypaleblue) url("../../images/about-bg.svg") left bottom no-repeat;
    background-size: auto 95%;
    display: flex;
    align-items: center;
    @media (max-width: 991.98px) {
        padding: 32px 0 !important;
    }

    @media (max-width: 1299.98px) {
        background-image: none;
        min-height: auto;
    }
    @media (max-width: 1399.98px) {
        padding: 80px 0;
        background-size: auto 650px;
    }
    .about-content {
        > div:not(:last-child) {
            margin-bottom: 36px;
        }
        p {
            margin-top: 16px;
        }
    }

    > .MuiContainer-root {
        > .MuiGrid2-direction-xs-row {
            > .MuiGrid2-grid-lg-6 {
                @media (max-width: 1299.98px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}

.getstart-section {
    padding: 136px 0 60px;
    @media (max-width: 991.98px) {
        padding: 32px 0 0 !important;
    }
    @media (max-width: 1399.98px) {
        padding: 80px 0 50px;
    }
    .getstarted-box {
        .MuiGrid2-grid-lg-3,
        .MuiGrid2-grid-lg-9 {
            @media (min-width: 991.99px) and (max-width: 1299.98px) {
                width: 100%;
            }
        }
    }
    .get-start-content {
        p {
            margin-bottom: 30px;
            @media (max-width: 1299.98px) {
                margin-bottom: 20px;
            }
        }
    }
}

.box-purple {
    border-radius: 32px;
    background: var(--palemagenta);
    padding: 32px;
    @media (max-width: 767.98px) {
        padding: 32px 28px;
        border-radius: 24px;
    }
    @media (max-width: 429.98px) {
        padding: 28px 24px;
    }
    .box-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
            @media (max-width: 767.98px) {
                width: 120px;
                display: flex;
                align-items: flex-start;
                margin-left: 12px;
            }
            @media (max-width: 575.98px) {
                width: 90px;
            }
            @media (max-width: 429.98px) {
                display: none;
            }
        }
    }
    .box-header-left {
        @media (max-width: 767.98px) {
            flex: 1;
        }
        p {
            max-width: 520px;

            @media (max-width: 991.98px) {
                max-width: 400px;
            }
            @media (max-width: 767.98px) {
                max-width: inherit;
            }
        }
    }
    .box-content {
        display: flex;
        flex-direction: column;
        @media (max-width: 767.98px) {
            margin-top: 30px;
        }
        .box-link-left {
            display: flex;
            @media (max-width: 575.98px) {
                align-items: flex-start;
            }
            @media (max-width: 429.98px) {
                flex-direction: column;
            }
            span {
                margin-right: 32px;
                display: flex;
                width: 60px;
                align-items: center;
                justify-content: center;
                @media (max-width: 575.98px) {
                    margin-top: 8px;
                    margin-right: 24px;
                    width: 80px;
                }
                @media (max-width: 429.98px) {
                    width: 40px;
                    margin-bottom: 16px;
                    margin-right: 0;
                }
            }
            div {
                max-width: 300px;
                word-break: break-all;
            }
            h5 {
                transition: ease-in-out 0.4s;
            }
            p {
                font-size: var(--font-size-14);
                margin-top: 8px;
                word-wrap: break-word;
                line-height: 20px;
            }
        }
        .box-link-right {
            @media (max-width: 575.98px) {
                flex: 1 1 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 16px;
                margin-left: 0;
            }
            margin-left: 12px;
            span {
                width: 48px;
                height: 48px;
                background: var(--White);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: ease-in 0.4s;
                .MuiSvgIcon-root {
                    color: var(--moderatevioletcustom);
                    transition: ease-in 0.4s;
                }
                @media (max-width: 767.98px) {
                    width: 40px;
                    height: 40px;
                }
                @media (max-width: 575.98px) {
                    width: 36px;
                    height: 36px;

                    .MuiSvgIcon-root {
                        font-size: var(--font-size-20);
                    }
                }
            }
        }
        a {
            display: flex;
            justify-content: space-between;
            padding: 22px 30px;
            background: var(--paleviolet);
            border-radius: 24px;
            border: 2px solid transparent;
            color: var(--black);
            align-items: center;
            @media (max-width: 767.98px) {
                padding: 16px 24px;
                border-radius: 20px;
            }
            @media (max-width: 575.98px) {
                flex-wrap: wrap;
            }
            @media (max-width: 429.98px) {
                padding: 24px 20px;
                border-radius: 16px;
            }
            &:hover {
                border: 2px solid rgba(140, 50, 183, 0.5);
                .box-link-left {
                    h5 {
                        color: var(--moderatevioletcustom);
                    }
                }
                .box-link-right {
                    span {
                        background: var(--moderatevioletcustom);
                        .MuiSvgIcon-root {
                            color: var(--White);
                        }
                    }
                }
            }
        }
    }
}

.box-blue {
    @extend .box-purple;
    background: var(--verywhiteblue);
    .box-content {
        .box-link-right {
            span {
                background: var(--White);
                .MuiSvgIcon-root {
                    color: var(--darkbluehome);
                }
            }
        }
        a {
            background: var(--verypaleblue);
            &:hover {
                border: 2px solid rgba(1, 53, 157, 0.5);
                .box-link-left {
                    h5 {
                        color: var(--darkbluehome);
                    }
                }
                .box-link-right {
                    span {
                        background: var(--darkbluehome);
                        .MuiSvgIcon-root {
                            color: var(--White);
                        }
                    }
                }
            }
        }
    }
}

.box-main {
    > section:first-child {
        margin-bottom: 40px;
    }
    @media (max-width: 991.98px) {
        margin-bottom: 0px !important;
    }
    @media (max-width: 1299.98px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        > section {
            margin-bottom: 30px !important;
        }
    }
}

.bt-1 {
    border-top: 1px solid var(--lightgrayishblue);
}

.payer-box {
    padding-bottom: 120px;
    background: url("../../images/footer-bg.svg") left bottom no-repeat;
    @media (max-width: 991.98px) {
        padding-bottom: 32px !important;
    }
    @media (max-width: 1399.98px) {
        background-size: 280px;
    }
    @media (max-width: 1299.98px) {
        background-image: none;
        padding-bottom: 80px;
    }
    .MuiGrid2-grid-lg-3 {
        @media (max-width: 1299.98px) {
            padding-right: 0;
        }
    }
    .MuiGrid2-grid-lg-3,
    .MuiGrid2-grid-lg-9 {
        @media (min-width: 991.99px) and (max-width: 1299.98px) {
            width: 100%;
        }
    }
    // .payer-view {
    //     @media (max-width: 1299.98px) {
    //         margin-top: 30px;
    //     }
    // }
}

.payer-content {
    margin-bottom: 64px;

    @media (max-width: 1299.98px) {
        margin-bottom: 32px;
    }
    h4 {
        margin-bottom: 30px;
        @media (max-width: 1299.98px) {
            margin-bottom: 20px;
        }
    }
}

.payer-grid-view {
    h5 {
        display: flex;
        align-items: center;
        color: var(--darkblue);
        font-weight: var(--font-weight-semi-bold);
        cursor: pointer;
        align-self: flex-start;
        .MuiSvgIcon-root {
            font-size: var(--font-size-24);
            margin-right: 6px;
        }
    }
    .payer-table {
        .MuiPaper-root {
            box-shadow: none;
            border-radius: 24px;
            border: 1px solid var(--lightgrayishblue);
        }
    }
    .MuiTable-root {
        border-radius: 24px;
        thead {
            background: var(--lightgrayishbluehome);
            border-radius: 24px 24px 0 0;
            th {
                font-family: "Poppins", sans-serif;
                font-size: var(--font-size-14);
                line-height: 20px;
                letter-spacing: 0;
                padding: 18px 30px;
                color: var(--black);
                border-bottom: 1px solid var(--lightgrayishblue);
            }
            @media (max-width: 1299.98px) {
                border-radius: 16px 16px 0 0;
                th {
                    padding: 14px 24px;
                }
            }
        }
        tbody {
            .MuiTableCell-root {
                font-family: "Poppins", sans-serif;
                padding: 16px 30px;
                border-bottom: 1px solid var(--lightgrayishblue);
                letter-spacing: 0;
                &:first-child::after,
                &:last-child::after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 4px;
                    background: var(--White);
                    bottom: -2px;
                    left: 0;
                }
                &:last-child::after {
                    left: auto;
                    right: 0;
                }

                @media (max-width: 1299.98px) {
                    padding: 14px 24px;
                    &:first-child::after,
                    &:last-child::after {
                        width: 24px;
                    }
                }
            }
            .MuiTableRow-root {
                position: relative;
                &:last-child {
                    .MuiTableCell-root {
                        border-bottom: 0;
                        &:first-child::after,
                        &:last-child::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .MuiTypography-root {
            color: var(--black);
            display: flex;
            align-items: center;
            font-size: var(--font-size-14);
            letter-spacing: 0;
            .MuiSvgIcon-root {
                margin-left: 20px;
                font-size: var(--font-size-20);
            }
            &:hover {
                color: var(--moderatevioletcustom);
            }
        }
    }

    .payer-column {
        display: flex;
        align-items: center;
        span {
            display: flex;
            width: 55px;
            height: 55px;
            background: var(--White);
            border-radius: 50%;
            border: 1px solid var(--lightgrayishblue);
            align-items: center;
            justify-content: center;

            @media (max-width: 1299.98px) {
                width: 44px;
                height: 44px;
            }
        }
        label {
            margin-left: 16px;
            font-size: var(--font-size-16);
            font-weight: var(--font-weight-medium);
            color: var(--black);
            flex: 1;
            white-space: nowrap;

            @media (max-width: 1299.98px) {
                font-size: var(--font-size-14);
                margin-left: 12px;
            }
        }
    }
}

footer {
    font-size: var(--font-size-14);
    color: var(--verydarkbluecustom);

    .footer-left {
        @media (min-width: 991.99px) and (max-width: 1299.98px) {
            width: 100%;
        }

        span {
            @media (max-width: 1299.98px) {
                display: none;
            }
        }
    }

    .footer-right {
        @media (min-width: 991.99px) and (max-width: 1299.98px) {
            width: 100%;
            margin-top: 8px;
        }
        li {
            line-height: 28px;
            span {
                display: flex;
                align-items: center;
            }
            a {
                color: var(--verydarkbluecustom);
                &:hover,
                &:focus {
                    color: var(--moderatevioletcustom);
                }
            }
            .MuiSvgIcon-root {
                font-size: var(--font-size-18);
                margin-right: 10px;
            }
        }
        address {
            line-height: 28px;
            font-style: normal;
        }
        .social-icons {
            display: flex;
            justify-content: flex-end;

            @media (max-width: 991.98px) {
                justify-content: flex-start;
            }

            .MuiSvgIcon-root {
                font-size: var(--font-size-32);
            }

            .linkedin {
                .MuiSvgIcon-root {
                    color: var(--softblue);
                }
            }
        }
        @media (max-width: 429.98px) {
            .MuiGrid2-grid-xs-6 {
                width: 100%;
                order: 1;
                margin-top: 12px;
            }
        }
    }
    .copy-right {
        display: none;
        margin-top: 30px;
        @media (max-width: 1299.98px) {
            display: block;
        }
        @media (max-width: 991.98px) {
            margin-top: 24px;
        }
    }
}
