.api-box {
    background: var(--darkmoderatebluecustom);
    border-radius: 16px;
    justify-content: flex-start;
    display: flex;
    max-height: 72px;
    .MuiListItemButton-root {
        &:hover {
            background: transparent;
        }
    }
    .MuiTouchRipple-root {
        display: none;
    }
    .get-item {
        background: var(--darklimegreen);
        border-radius: 8px;
        width: 56px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0px;
        color: var(--White);
    }

    .request-box {
        display: flex;
        padding-left: 16px;
        justify-content: space-between;
        width: 100%;
        .MuiTypography-root {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .request-url {
            color: var(--black);
            font-weight: var(--font-weight-medium);
            letter-spacing: 0px;
            .request-info {
                font-size: var(--font-size-12);
                font-weight: var(--font-weight-semi-bold);
            }
            .request-path {
                font-size: var(--font-size-14);
                font-weight: var(--font-weight-medium);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .copy-icon {
        i {
            font-size: 24px;
        }
    }
}
