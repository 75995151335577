@import url("../src/components/scss/colors.scss");
@import url("../src/components/scss/font.scss");
@import "react-perfect-scrollbar/dist/css/styles.css";

.custom-controllers {
    .MuiInputLabel-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85%;
    }
    textarea {
        position: relative;
        top: 10px;
        @media (min-width: 1537px) {
            padding-left: 10px;
        }
        transition: height 0.3s ease;
    }
    .MuiInputBase-root {
        background: transparent !important;
        border-radius: 12px;
        padding-right: 12px;
        @media (min-width: 1537px) {
            padding-right: 24px;
        }
        i {
            color: var(--desaturatedbluecustom);
            font-size: 24px;
        }
        &::before {
            top: 0;
            border-radius: 12px;
            border: 1px solid var(--grayishblue);
        }
        &::after {
            display: none;
        }
        &:hover:not(.Mui-disabled, .Mui-error):before {
            border: 2px solid var(--grayishblue);
        }
        &.Mui-disabled {
            &::before {
                border: 2px dotted;
            }
        }
        &.MuiFilledInput-root {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // &:hover::before {
            //     border: 2px solid var(--vividbluecustom);
            // }
            // &::before {
            //     border: 2px solid var(--vividbluecustom);
            // }
            &.Mui-focused {
                i {
                    color: var(--vividbluecustom);
                }
                &::before {
                    border: 2px solid var(--vividbluecustom);
                }
                .MuiFilledInput-input {
                    color: var(--darkblue);
                }
            }
            &.Mui-error {
                &::before {
                    top: 0;
                    border-radius: 12px;
                    border: 1px solid var(--Strongred);
                }
                i {
                    color: var(--Strongred);
                }
                &.Mui-focused {
                    &::before {
                        border: 2px solid var(--Strongred);
                    }
                }
                .MuiFilledInput-input {
                    color: var(--Strongred);
                }
            }
            .MuiFilledInput-input {
                border-radius: 12px;
                padding-left: 12px;
                letter-spacing: 0.16px;
                font-size: var(--font-size-16);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.Mui-disabled {
                    -webkit-text-fill-color: var(--black);
                }
                @media (min-width: 1537px) {
                    padding-left: 24px;
                }
            }
        }
    }
    .MuiFormControl-root {
        margin-bottom: 24px;
        margin-top: 0;
    }
    .MuiFormHelperText-root {
        position: absolute;
        bottom: -22px;
        margin-left: 24px;
        &.Mui-error {
            color: var(--Strongred);
        }
    }
    .MuiFormLabel-root {
        left: 0px;
        letter-spacing: 0px;
        color: #515977;
        font-size: var(--font-size-16);
        @media (min-width: 1537px) {
            left: 12px;
        }
        &.Mui-focused {
            color: var(--vividblue);
        }
        &.Mui-error {
            color: var(--Strongred);
        }
    }
}
.center-content {
    margin-top: 120px;
    padding: 36px;
    width: 100%;
    background: var(--verywhiteblue);
    height: calc(100vh - 120px);
    overflow: auto;
    @media (min-width: 900px) {
        padding: 36px 36px 36px 32px;
    }
    @media (min-width: 1200px) {
        padding: 36px 36px 36px 350px;
    }
    @media (min-width: 1537px) {
        padding: 32px 88px 32px 440px;
    }
}
.page-layout {
    margin-top: 120px;
    padding: 36px;
    width: 100%;
    background: var(--verywhiteblue);
    height: calc(100vh - 120px);
    overflow: auto;
    @media (min-width: 900px) {
        padding: 36px 438px 36px 32px;
    }
    @media (min-width: 1200px) {
        padding: 36px 484px 36px 350px;
    }
    @media (min-width: 1537px) {
        padding: 36px 600px 36px 440px;
    }
}
.user-btn {
    &.MuiButtonBase-root {
        border-radius: 16px;
        padding: 12px 16px;
        text-transform: capitalize;
    }
    svg {
        color: var(--White);
    }
    &.home-page {
        .list-info {
            display: none;
            .welcome-info {
                color: var(--White);
            }
            @media (min-width: 1200px) {
                display: block;
            }
        }
    }
    .list-info {
        margin-right: 24px;
        .welcome-info {
            font-weight: 500;
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            color: #bcd1ff;
            text-align: right;
        }
        .user-info {
            text-align: right;
            font-weight: 600;
            font-size: var(--font-size-22);
            letter-spacing: 0px;
            color: var(--White);
            line-height: 22px;
            width: max-content;
        }
    }
    .user-letter {
        min-height: 48px;
        min-width: 48px;
        background: var(--black) linear-gradient(90deg, var(--SecondaryBrightviolet), var(--vividbluecustom) 122%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: var(--font-size-16);
        font-weight: 500;
        letter-spacing: 0.8px;
        color: var(--White);
    }
}
.user-profile-dropdown {
    .logout-menu {
        display: flex;
        justify-content: space-between;
    }
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
