:root {
    /* Font Size Variables */
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
    --font-size-22: 22px;
    --font-size-24: 24px;
    --font-size-26: 26px;
    --font-size-32: 32px;
    /* Font Weight Variables */
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
}
html {
    * {
        font-family: "Poppins", sans-serif !important;
    }
}
