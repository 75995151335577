.forgot {
    display: flex;
    margin-right: 0;
    height: calc(100vh);
    justify-content: center;
    align-items: center;
    background: url("../../images/Background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 900px) {
        justify-content: end;
    }
    .tab-logo {
        display: block;
        margin-bottom: 20px;
        @media (min-width: 900px) {
            display: none;
            margin-bottom: 0px;
        }
    }
    .product-logo {
        display: none;
        @media (min-width: 900px) {
            position: absolute;
            display: block;
            top: 100px;
            left: 100px;
            width: 216px;
            height: 80px;
            img {
                width: 100%;
            }
        }
        .version-info {
            text-align: right;
            font-size: var(--font-size-12);
            color: var(--White);
            position: relative;
            right: 8px;
            bottom: 8px;
        }
    }
    .forgot-box {
        height: max-content;
        background: var(--White);
        max-width: 420px;

        padding: 32px;
        overflow: auto;
        border-radius: 32px;
        @media (min-width: 900px) {
            padding: 0 48px;
            width: 420px;
            height: calc(100vh);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-radius: 0;
        }
        @media (min-width: 1200px) {
            padding: 0 98px;
        }
        @media (min-width: 1537px) {
            padding: 0 128px;
        }
        .back-info {
            display: none;
            @media (min-width: 900px) {
                display: flex;
                a {
                    display: flex;
                    color: var(--black);
                    align-items: center;
                    i {
                        margin-right: 8px;
                        font-size: 20px;
                        font-weight: bold;
                    }
                    p {
                        font-weight: 600;
                        font-size: var(--font-size-14);
                        letter-spacing: 0px;
                        color: var(--black);
                    }
                }
            }
        }
    }
    .header-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        h1 {
            font-weight: 600;
            font-size: var(--font-size-32);
            letter-spacing: 0px;
            color: var(--black);
            line-height: 32px;
        }
        .signup {
            display: flex;
            flex-direction: column;
            align-items: end;
            .info {
                font-size: var(--font-size-16);
                font-weight: 500;
                letter-spacing: 0px;
                color: var(--black);
            }
            .MuiLink-root {
                text-decoration: underline;
                font-size: var(--font-size-16);
                font-weight: 500;
                letter-spacing: 0px;
                color: var(--moderatevioletcustom);
            }
        }
    }
    .sub-info {
        font-size: var(--font-size-16);
        letter-spacing: 0px;
        color: var(--desaturatedbluecustom);
        margin-bottom: 42px;
    }
    .reset-btn {
        border-radius: 16px;
        height: 56px;
        background: var(--black) linear-gradient(90deg, var(--SecondaryBrightviolet), var(--vividbluecustom) 122%);
        font-size: var(--font-size-16);
        font-weight: 600;
        letter-spacing: 0px;
        color: var(--White);
        text-transform: unset;
        margin-bottom: 40px;
        &.Mui-disabled {
            background: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }
    .footer-info {
        position: relative;
        .pwd-txt {
            font-size: var(--font-size-11);
            font-weight: 500;
            letter-spacing: 0px;
            color: var(--desaturatedbluecustom);
            position: absolute;
            right: 0;
            bottom: 20px;
        }
        .content-center {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .copyright-txt {
            font-size: var(--font-size-14);
            letter-spacing: 0px;
            color: var(--desaturatedbluecustom);
        }
        .company-logo {
            width: 128px;
        }
    }
}
