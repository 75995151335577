@font-face {
  font-family: "connect";
  src: url("fonts/connect.eot?qcnvat");
  src:
    url("fonts/connect.eot?qcnvat#iefix") format("embedded-opentype"),
    url("fonts/connect.woff2?qcnvat") format("woff2"),
    url("fonts/connect.ttf?qcnvat") format("truetype"),
    url("fonts/connect.woff?qcnvat") format("woff"),
    url("fonts/connect.svg?qcnvat#connect") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i,
.ac_icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "connect" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ac_union:before {
  content: "\eac6";
}
.ac_copy:before {
  content: "\ea09";
}
.ac_arrow_down_circle_bold_line:before {
  content: "\e900";
}
.ac_arrow_down_circle_line:before {
  content: "\e901";
}
.ac_download_fill:before {
  content: "\e902";
}
.ac_facebook:before {
  content: "\e903";
}
.ac_laptop:before {
  content: "\e904";
}
.ac_laptop_install:before {
  content: "\e905";
}
.ac_linkedin:before {
  content: "\e906";
}
.ac_twitter:before {
  content: "\e907";
}
.ac_check_in:before {
  content: "\e908";
}
.ac_check_out_left:before {
  content: "\e909";
}
.ac_check_out_right:before {
  content: "\e90a";
}
.ac_door_pending_close:before {
  content: "\e90b";
}
.ac_door_pending_fill:before {
  content: "\e90c";
}
.ac_door_pending_line:before {
  content: "\e90d";
}
.ac_door_pending_process:before {
  content: "\e90e";
}
.ac_user_chart:before {
  content: "\e90f";
}
.ac_user_chart_2:before {
  content: "\e910";
}
.ac_user_chart_2_fill:before {
  content: "\e911";
}
.ac_user_chart_3:before {
  content: "\e912";
}
.ac_user_chart_4:before {
  content: "\e913";
}
.ac_color_palette:before {
  content: "\e914";
}
.ac_error:before {
  content: "\e915";
}
.ac_unsign_note:before {
  content: "\e916";
}
.ac_calendar_view:before {
  content: "\e917";
}
.ac_doc_fill:before {
  content: "\e918";
}
.ac_bmi:before {
  content: "\e919";
}
.ac_doc_review_fill:before {
  content: "\e91a";
}
.ac_docs_fill:before {
  content: "\e91b";
}
.ac_group_view:before {
  content: "\e91c";
}
.ac_heart_2_fill:before {
  content: "\e91d";
}
.ac_heart_fill:before {
  content: "\e91e";
}
.ac_heart_line:before {
  content: "\e91f";
}
.ac_note_fill:before {
  content: "\e920";
}
.ac_note_line:before {
  content: "\e921";
}
.ac_progress_note_fill:before {
  content: "\e922";
}
.ac_progress_note_line:before {
  content: "\e923";
}
.ac_user_note:before {
  content: "\e924";
}
.ac_chat:before {
  content: "\e925";
}
.ac_post_treatment_fill:before {
  content: "\e926";
}
.ac_post_treatment_line:before {
  content: "\e927";
}
.ac_profile:before {
  content: "\e928";
}
.ac_task_2_fill:before {
  content: "\e929";
}
.ac_task_line:before {
  content: "\e92a";
}
.ac_bell_ring_fill:before {
  content: "\e92b";
}
.ac_cake_fill:before {
  content: "\e92c";
}
.ac_cake_line:before {
  content: "\e92d";
}
.ac_chart_fill:before {
  content: "\e92e";
}
.ac_chart_line:before {
  content: "\e92f";
}
.ac_dashboard_line:before {
  content: "\e930";
}
.ac_medicine_fill:before {
  content: "\e931";
}
.ac_medicine_line:before {
  content: "\e932";
}
.ac_phone_fill:before {
  content: "\e933";
}
.ac_save:before {
  content: "\e934";
}
.ac_user_phone_fill:before {
  content: "\e935";
}
.ac_bmi_meter:before {
  content: "\e936";
}
.ac_bell_fill:before {
  content: "\e937";
}
.ac_dashboard_fill:before {
  content: "\e938";
}
.ac_home_fill:before {
  content: "\e939";
}
.ac_home_line:before {
  content: "\e93a";
}
.ac_patient:before {
  content: "\e93b";
}
.ac_patient_call:before {
  content: "\e93c";
}
.ac_three_group:before {
  content: "\e93d";
}
.ac_two_group:before {
  content: "\e93e";
}
.ac_user:before {
  content: "\e93f";
}
.ac_apple:before {
  content: "\e940";
}
.ac_user_fill:before {
  content: "\e941";
}
.ac_user_line:before {
  content: "\e942";
}
.ac_clock:before {
  content: "\e943";
}
.ac_expand:before {
  content: "\e944";
}
.ac_bowl_food:before {
  content: "\e945";
}
.ac_filter_fill:before {
  content: "\e946";
}
.ac_food:before {
  content: "\e947";
}
.ac_burger:before {
  content: "\e948";
}
.ac_fullscreen:before {
  content: "\e949";
}
.ac_fullscreen_exit:before {
  content: "\e94a";
}
.ac_log_off:before {
  content: "\e94b";
}
.ac_log_off_2:before {
  content: "\e94c";
}
.ac_flower_leaf:before {
  content: "\e94d";
}
.ac_bp:before {
  content: "\e94e";
}
.ac_bp_diastolic_down:before {
  content: "\e94f";
}
.ac_translate:before {
  content: "\e950";
}
.ac_bp_systolic_up:before {
  content: "\e951";
}
.ac_body_mass_meter:before {
  content: "\e952";
}
.ac_diagnosis_body:before {
  content: "\e953";
}
.ac_diagnosis:before {
  content: "\e954";
}
.ac_eye_off_line:before {
  content: "\e955";
}
.ac_bp_lying_down:before {
  content: "\e956";
}
.ac_help:before {
  content: "\e957";
}
.ac_pending:before {
  content: "\e958";
}
.ac_bp_sitting:before {
  content: "\e959";
}
.ac_type:before {
  content: "\e95a";
}
.ac_menu_1:before {
  content: "\e95b";
}
.ac_bp_standing:before {
  content: "\e95c";
}
.ac_breath:before {
  content: "\e95d";
}
.ac_arrow_left:before {
  content: "\e95e";
}
.ac_arrow_right:before {
  content: "\e95f";
}
.ac_arrow_up:before {
  content: "\e960";
}
.ac_arrow_down:before {
  content: "\e961";
}
.ac_arrow_down_fill:before {
  content: "\e962";
}
.ac_arrow_up_fill:before {
  content: "\e963";
}
.ac_arrow_left_fill:before {
  content: "\e964";
}
.ac_arrow_right_fill:before {
  content: "\e965";
}
.ac_care_team:before {
  content: "\e966";
}
.ac_check_done:before {
  content: "\e967";
}
.ac_arrow_right_line:before {
  content: "\e968";
}
.ac_close:before {
  content: "\e969";
}
.ac_doctor_group:before {
  content: "\e96a";
}
.ac_arrow_down_line:before {
  content: "\e96b";
}
.ac_arrow_left_line:before {
  content: "\e96c";
}
.ac_arrow_up_line:before {
  content: "\e96d";
}
.ac_doctor_1:before {
  content: "\e96e";
}
.ac_more_circle_line:before {
  content: "\e96f";
}
.ac_more_horizontal:before {
  content: "\e970";
}
.ac_doctor_group_1:before {
  content: "\e971";
}
.ac_search:before {
  content: "\e972";
}
.ac_key_fill:before {
  content: "\e973";
}
.ac_key_line:before {
  content: "\e974";
}
.ac_translator:before {
  content: "\e975";
}
.ac_advanced_directive:before {
  content: "\e976";
}
.ac_pencil_box_line:before {
  content: "\e977";
}
.ac_logout:before {
  content: "\e978";
}
.ac_360:before {
  content: "\e979";
}
.ac_access_alarm:before {
  content: "\e97a";
}
.ac_accessibility:before {
  content: "\e97b";
}
.ac_accessibility_new:before {
  content: "\e97c";
}
.ac_accessible:before {
  content: "\e97d";
}
.ac_accessible_forward:before {
  content: "\e97e";
}
.ac_account_balance_line:before {
  content: "\e97f";
}
.ac_account_balance_fill:before {
  content: "\e980";
}
.ac_account_balance_wallet_line:before {
  content: "\e981";
}
.ac_account_balance_wallet_fill:before {
  content: "\e982";
}
.ac_account_box_line:before {
  content: "\e983";
}
.ac_account_box_fill:before {
  content: "\e984";
}
.ac_add:before {
  content: "\e985";
}
.ac_add_circle:before {
  content: "\e986";
}
.ac_add_circle_line:before {
  content: "\e987";
}
.ac_alternate_email:before {
  content: "\e988";
}
.ac_apps:before {
  content: "\e989";
}
.ac_archive:before {
  content: "\e98a";
}
.ac_arrow_drop_down_circle:before {
  content: "\e98b";
}
.ac_arrow_drop_down_circle_fill:before {
  content: "\e98c";
}
.ac_fax:before {
  content: "\e98d";
}
.ac_assessment_line:before {
  content: "\e98e";
}
.ac_assessment_fill:before {
  content: "\e98f";
}
.ac_assignment:before {
  content: "\e990";
}
.ac_assignment_line:before {
  content: "\e991";
}
.ac_assignment_fill:before {
  content: "\e992";
}
.ac_flag_line:before {
  content: "\e993";
}
.ac_flag_fill:before {
  content: "\e994";
}
.ac_attachment_vertical:before {
  content: "\e995";
}
.ac_attachment_horizontal:before {
  content: "\e996";
}
.ac_auto_renew:before {
  content: "\e997";
}
.ac_backup_line:before {
  content: "\e998";
}
.ac_backup_fill:before {
  content: "\e999";
}
.ac_bar_chart:before {
  content: "\e99a";
}
.ac_block:before {
  content: "\e99b";
}
.ac_business_bag_line:before {
  content: "\e99c";
}
.ac_business_bag_fill:before {
  content: "\e99d";
}
.ac_business_building:before {
  content: "\e99e";
}
.ac_calendar_today_line:before {
  content: "\e99f";
}
.ac_calendar_today_fill:before {
  content: "\e9a0";
}
.ac_cancel:before {
  content: "\e9a1";
}
.ac_cancel_fill:before {
  content: "\e9a2";
}
.ac_card_travel:before {
  content: "\e9a3";
}
.ac_chat_line:before {
  content: "\e9a4";
}
.ac_chat_fill:before {
  content: "\e9a5";
}
.ac_check_box_checked:before {
  content: "\e9a6";
}
.ac_check_box_unchecked:before {
  content: "\e9a7";
}
.ac_clear_all:before {
  content: "\e9a8";
}
.ac_compare_arrows:before {
  content: "\e9a9";
}
.ac_copyright:before {
  content: "\e9aa";
}
.ac_credit_card:before {
  content: "\e9ab";
}
.ac_medical_director_2:before {
  content: "\e9ac";
}
.ac_medical_director:before {
  content: "\e9ad";
}
.ac_delete_forever_fill:before {
  content: "\e9ae";
}
.ac_delete_forever_line:before {
  content: "\e9af";
}
.ac_delete:before {
  content: "\e9b0";
}
.ac_email_line:before {
  content: "\e9b1";
}
.ac_email_fill:before {
  content: "\e9b2";
}
.ac_director:before {
  content: "\e9b3";
}
.ac_error_line:before {
  content: "\e9b4";
}
.ac_event_line:before {
  content: "\e9b5";
}
.ac_event_available_line:before {
  content: "\e9b6";
}
.ac_event_available_fill:before {
  content: "\e9b7";
}
.ac_event_busy_line:before {
  content: "\e9b8";
}
.ac_arrow_right_alt:before {
  content: "\e9b9";
}
.ac_event_fill:before {
  content: "\e9ba";
}
.ac_favorite_line:before {
  content: "\e9bb";
}
.ac_favorite_fill:before {
  content: "\e9bc";
}
.ac_file_copy_line:before {
  content: "\e9bd";
}
.ac_file_copy_fill:before {
  content: "\e9be";
}
.ac_filter_list:before {
  content: "\e9bf";
}
.ac_folder_line:before {
  content: "\e9c0";
}
.ac_folder_fill:before {
  content: "\e9c1";
}
.ac_forward_line:before {
  content: "\e9c2";
}
.ac_forward_fill:before {
  content: "\e9c3";
}
.ac_help_fill:before {
  content: "\e9c4";
}
.ac_help_line:before {
  content: "\e9c5";
}
.ac_history:before {
  content: "\e9c6";
}
.ac_doctor:before {
  content: "\e9c7";
}
.ac_specialist_doctor:before {
  content: "\e9c8";
}
.ac_import_contacts:before {
  content: "\e9c9";
}
.ac_import_export:before {
  content: "\e9ca";
}
.ac_inbox_fill:before {
  content: "\e9cb";
}
.ac_inbox_line:before {
  content: "\e9cc";
}
.ac_insert_drive_file_line:before {
  content: "\e9cd";
}
.ac_insert_drive_file_fill:before {
  content: "\e9ce";
}
.ac_insert_link:before {
  content: "\e9cf";
}
.ac_label_fill:before {
  content: "\e9d0";
}
.ac_label_line:before {
  content: "\e9d1";
}
.ac_list:before {
  content: "\e9d2";
}
.ac_list_alt:before {
  content: "\e9d3";
}
.ac_local_hospital_line:before {
  content: "\e9d4";
}
.ac_local_hospital_fill:before {
  content: "\e9d5";
}
.ac_location_city:before {
  content: "\e9d6";
}
.ac_specialist:before {
  content: "\e9d7";
}
.ac_lock_line:before {
  content: "\e9d8";
}
.ac_lock_fill:before {
  content: "\e9d9";
}
.ac_money:before {
  content: "\e9da";
}
.ac_move_to_inbox_line:before {
  content: "\e9db";
}
.ac_move_to_inbox_fill:before {
  content: "\e9dc";
}
.ac_pdf_fill:before {
  content: "\e9dd";
}
.ac_pdf_line:before {
  content: "\e9de";
}
.ac_remove_circle_fill:before {
  content: "\e9df";
}
.ac_remove_circle_line:before {
  content: "\e9e0";
}
.ac_reply:before {
  content: "\e9e1";
}
.ac_reply_all:before {
  content: "\e9e2";
}
.ac_save_alt:before {
  content: "\e9e3";
}
.ac_sent:before {
  content: "\e9e4";
}
.ac_star_fill:before {
  content: "\e9e5";
}
.ac_star_line:before {
  content: "\e9e6";
}
.ac_timeline:before {
  content: "\e9e7";
}
.ac_today_line:before {
  content: "\e9e8";
}
.ac_today_fill:before {
  content: "\e9e9";
}
.ac_trending_down:before {
  content: "\e9ea";
}
.ac_trending_up:before {
  content: "\e9eb";
}
.ac_special_user:before {
  content: "\e9ec";
}
.ac_dr_specialist:before {
  content: "\e9ed";
}
.ac_update:before {
  content: "\e9ee";
}
.ac_nurse_practitioner:before {
  content: "\e9ef";
}
.ac_nurse:before {
  content: "\e9f0";
}
.ac_view_quilt_fill:before {
  content: "\e9f1";
}
.ac_view_quilt_line:before {
  content: "\e9f2";
}
.ac_text_fields:before {
  content: "\e9f3";
}
.ac_web_asset:before {
  content: "\e9f4";
}
.ac_crop_portrait:before {
  content: "\e9f5";
}
.ac_ballot_fill:before {
  content: "\e9f6";
}
.ac_ballot_line:before {
  content: "\e9f7";
}
.ac_build_fill:before {
  content: "\e9f8";
}
.ac_build_line:before {
  content: "\e9f9";
}
.ac_receipt_fill:before {
  content: "\e9fa";
}
.ac_receipt_line:before {
  content: "\e9fb";
}
.ac_home_nurse:before {
  content: "\e9fc";
}
.ac_school_fill:before {
  content: "\e9fd";
}
.ac_school_line:before {
  content: "\e9fe";
}
.ac_home_nurse_2:before {
  content: "\e9ff";
}
.ac_view_day:before {
  content: "\ea00";
}
.ac_drag_handle:before {
  content: "\ea01";
}
.ac_view_module_fill:before {
  content: "\ea02";
}
.ac_call_support:before {
  content: "\ea03";
}
.ac_view_week_fill:before {
  content: "\ea04";
}
.ac_view_week_line:before {
  content: "\ea05";
}
.ac_case_manager:before {
  content: "\ea06";
}
.ac_patient_wait:before {
  content: "\ea07";
}
.ac_measurement:before {
  content: "\ea08";
}
.ac_team_cordination_fill_2:before {
  content: "\ea0a";
}
.ac_team_cordination_line_2:before {
  content: "\ea0b";
}
.ac_emg_room:before {
  content: "\ea0c";
}
.ac_er_room:before {
  content: "\ea0d";
}
.ac_hospital_visit:before {
  content: "\ea0e";
}
.ac_fan_air:before {
  content: "\ea0f";
}
.ac_fish:before {
  content: "\ea10";
}
.ac_heart_pain:before {
  content: "\ea11";
}
.ac_care_hand:before {
  content: "\ea12";
}
.ac_mask_line:before {
  content: "\ea13";
}
.ac_mask_fill:before {
  content: "\ea14";
}
.ac_hospital_building:before {
  content: "\ea15";
}
.ac_office:before {
  content: "\ea16";
}
.ac_pharmacy:before {
  content: "\ea17";
}
.ac_event_busy_fill:before {
  content: "\ea18";
}
.ac_bottle_med:before {
  content: "\ea19";
}
.ac_bottle_med_2:before {
  content: "\ea1a";
}
.ac_drug_cancel_1:before {
  content: "\ea1b";
}
.ac_drug_cancel_2:before {
  content: "\ea1c";
}
.ac_eye_dropper:before {
  content: "\ea1d";
}
.ac_needle:before {
  content: "\ea1e";
}
.ac_pill_capsule_med:before {
  content: "\ea1f";
}
.ac_spoon_med:before {
  content: "\ea20";
}
.ac_tablet:before {
  content: "\ea21";
}
.ac_tablet_med:before {
  content: "\ea22";
}
.ac_scale:before {
  content: "\ea23";
}
.ac_temperature_meter:before {
  content: "\ea24";
}
.ac_stethoscope:before {
  content: "\ea25";
}
.ac_temperature_meter_2:before {
  content: "\ea26";
}
.ac_e5f7ff:before {
  content: "\ea27";
}
.ac_sad_line:before {
  content: "\ea28";
}
.ac_summary:before {
  content: "\ea29";
}
.ac_timeline_2:before {
  content: "\ea2a";
}
.ac_timer_line:before {
  content: "\ea2b";
}
.ac_umbrella:before {
  content: "\ea2c";
}
.ac_check_decagram:before {
  content: "\ea2d";
}
.ac_alert_shape:before {
  content: "\ea2e";
}
.ac_alert_rhombus:before {
  content: "\ea2f";
}
.ac_pentagon:before {
  content: "\ea30";
}
.ac_hexagon:before {
  content: "\ea31";
}
.ac_star_four_points:before {
  content: "\ea32";
}
.ac_star_three_points:before {
  content: "\ea33";
}
.ac_star:before {
  content: "\ea34";
}
.ac_hexagram:before {
  content: "\ea35";
}
.ac_leaf:before {
  content: "\ea36";
}
.ac_circle_dots:before {
  content: "\ea37";
}
.ac_utilize:before {
  content: "\ea38";
}
.ac_dot_big:before {
  content: "\ea39";
}
.ac_view_module_line:before {
  content: "\ea3a";
}
.ac_location_fill:before {
  content: "\ea3b";
}
.ac_location_line:before {
  content: "\ea3c";
}
.ac_color_palette_line:before {
  content: "\ea3d";
}
.ac_create:before {
  content: "\ea3e";
}
.ac_doc_line:before {
  content: "\ea3f";
}
.ac_bell_line:before {
  content: "\ea40";
}
.ac_user_call:before {
  content: "\ea41";
}
.ac_filter_line:before {
  content: "\ea42";
}
.ac_female_line:before {
  content: "\ea43";
}
.ac_male_line:before {
  content: "\ea44";
}
.ac_pending_line:before {
  content: "\ea45";
}
.ac_pending_process_line:before {
  content: "\ea46";
}
.ac_pending_fill:before {
  content: "\ea47";
}
.ac_check_circle_line:before {
  content: "\ea48";
}
.ac_close_circle_line:before {
  content: "\ea49";
}
.ac_eye_fill:before {
  content: "\ea4a";
}
.ac_eye_off_fill:before {
  content: "\ea4b";
}
.ac_eye_line:before {
  content: "\ea4c";
}
.ac_more_vertical:before {
  content: "\ea4d";
}
.ac_menu:before {
  content: "\ea4e";
}
.ac_sms_popup:before {
  content: "\ea4f";
}
.ac_alarm:before {
  content: "\ea50";
}
.ac_county:before {
  content: "\ea51";
}
.ac_county_line:before {
  content: "\ea52";
}
.ac_credential:before {
  content: "\ea53";
}
.ac_delete_fill:before {
  content: "\ea54";
}
.ac_direction:before {
  content: "\ea55";
}
.ac_direction_line:before {
  content: "\ea56";
}
.ac_doctor_info:before {
  content: "\ea57";
}
.ac_do_not_call:before {
  content: "\ea58";
}
.ac_do_not_email:before {
  content: "\ea59";
}
.ac_do_not_sms:before {
  content: "\ea5a";
}
.ac_do_not_call_line:before {
  content: "\ea5b";
}
.ac_do_not_email_line:before {
  content: "\ea5c";
}
.ac_do_not_sms_line:before {
  content: "\ea5d";
}
.ac_event_cancel:before {
  content: "\ea5e";
}
.ac_event_left:before {
  content: "\ea5f";
}
.ac_health_home:before {
  content: "\ea60";
}
.ac_key:before {
  content: "\ea61";
}
.ac_lock:before {
  content: "\ea62";
}
.ac_lock_line1:before {
  content: "\ea63";
}
.ac_mobile:before {
  content: "\ea64";
}
.ac_office_phone:before {
  content: "\ea65";
}
.ac_home_phone:before {
  content: "\ea66";
}
.ac_work_phone:before {
  content: "\ea67";
}
.ac_print:before {
  content: "\ea68";
}
.ac_state:before {
  content: "\ea69";
}
.ac_state_line:before {
  content: "\ea6a";
}
.ac_text_para:before {
  content: "\ea6b";
}
.ac_unlock:before {
  content: "\ea6c";
}
.ac_unlock_line:before {
  content: "\ea6d";
}
.ac_wrong_call:before {
  content: "\ea6e";
}
.ac_mail_post:before {
  content: "\ea6f";
}
.ac_meet:before {
  content: "\ea70";
}
.ac_sms:before {
  content: "\ea71";
}
.ac_upload_line:before {
  content: "\ea72";
}
.ac_trending_left:before {
  content: "\ea73";
}
.ac_trending_right:before {
  content: "\ea74";
}
.ac_warning:before {
  content: "\ea75";
}
.ac_warning_line:before {
  content: "\ea76";
}
.ac_drug_cancel:before {
  content: "\ea77";
}
.ac_coder_review:before {
  content: "\ea78";
}
.ac_doc_view:before {
  content: "\ea79";
}
.ac_left_not_seen_app:before {
  content: "\ea7a";
}
.ac_appo_not_seen:before {
  content: "\ea7b";
}
.ac_appo_seen:before {
  content: "\ea7c";
}
.ac_physician_code_review:before {
  content: "\ea7d";
}
.ac_physician_code_review_1:before {
  content: "\ea7e";
}
.ac_add_task:before {
  content: "\ea7f";
}
.ac_appo_cancel_office:before {
  content: "\ea80";
}
.ac_appo_cancel_patient:before {
  content: "\ea81";
}
.ac_appo_confirm:before {
  content: "\ea82";
}
.ac_appo_pending:before {
  content: "\ea83";
}
.ac_audit_doc:before {
  content: "\ea84";
}
.ac_bill_ready:before {
  content: "\ea85";
}
.ac_close_task:before {
  content: "\ea86";
}
.ac_dr_patient:before {
  content: "\ea87";
}
.ac_export_file_xl:before {
  content: "\ea88";
}
.ac_new_task:before {
  content: "\ea89";
}
.ac_patient_wait_1:before {
  content: "\ea8a";
}
.ac_permission_doc:before {
  content: "\ea8b";
}
.ac_permission_doc_1:before {
  content: "\ea8c";
}
.ac_task_pending:before {
  content: "\ea8d";
}
.ac_task_progress:before {
  content: "\ea8e";
}
.ac_waiting_dr:before {
  content: "\ea8f";
}
.ac_waiting_dr_1:before {
  content: "\ea90";
}
.ac_waiting_medical_assistant:before {
  content: "\ea91";
}
.ac_waiting_medical_assistant_1:before {
  content: "\ea92";
}
.ac_cloud_download:before {
  content: "\ea93";
}
.ac_cloud_download_line:before {
  content: "\ea94";
}
.ac_folder_open_line:before {
  content: "\ea95";
}
.ac_open_in_new:before {
  content: "\ea96";
}
.ac_sync:before {
  content: "\ea97";
}
.ac_mic_black:before {
  content: "\ea98";
}
.ac_mic_off:before {
  content: "\ea99";
}
.ac_thumb_down:before {
  content: "\ea9a";
}
.ac_thumb_up:before {
  content: "\ea9b";
}
.ac_member_search_line:before {
  content: "\ea9c";
}
.ac_switch_toggle:before {
  content: "\ea9d";
}
.ac_subtract:before {
  content: "\ea9e";
}
.ac_search_dr:before {
  content: "\ea9f";
}
.ac_lab:before {
  content: "\eaa0";
}
.ac_dollar_circle_fill:before {
  content: "\eaa1";
}
.ac_zoom:before {
  content: "\eaa2";
}
.ac_nav_close:before {
  content: "\eaa3";
}
.ac_nav_open:before {
  content: "\eaa4";
}
.ac_arrow_expand:before {
  content: "\eaa5";
}
.ac_science:before {
  content: "\eaa6";
}
.ac_select_mem:before {
  content: "\eaa7";
}
.ac_select_mem_2:before {
  content: "\eaa8";
}
.ac_settings:before {
  content: "\eaa9";
}
.ac_settings_line:before {
  content: "\eaaa";
}
.ac_info_fill:before {
  content: "\eaab";
}
.ac_info_line:before {
  content: "\eaac";
}
.ac_asc:before {
  content: "\eaad";
}
.ac_dsc:before {
  content: "\eaae";
}
.ac_sort_2:before {
  content: "\eaaf";
}
.ac_verified_user:before {
  content: "\eab0";
}
.ac_unknown_gender:before {
  content: "\eab1";
}
.ac_nor_dash:before {
  content: "\eab2";
}
.ac_popup_3:before {
  content: "\eab3";
}
.ac_check_circle_fill:before {
  content: "\eab4";
}
.ac_tfa:before {
  content: "\eab5";
}
.ac_psychology:before {
  content: "\eab6";
}
.ac_link:before {
  content: "\eab7";
}
.ac_double_arrow_left:before {
  content: "\eab8";
}
.ac_double_arrow_right:before {
  content: "\eab9";
}
.ac_patient_search_1:before {
  content: "\eaba";
}
.ac_patient_search_2:before {
  content: "\eabb";
}
.ac_bulb:before {
  content: "\eabc";
}
.ac_notes:before {
  content: "\eabd";
}
.ac_id_card:before {
  content: "\eabe";
}
.ac_revert:before {
  content: "\eabf";
}
.ac_sorting:before {
  content: "\eac0";
}
.ac_user_search_1:before {
  content: "\eac1";
}
.ac_user_search_2:before {
  content: "\eac2";
}
.ac_application_window:before {
  content: "\eac3";
}
.ac_double_settings:before {
  content: "\eac4";
}
.ac_secure_sheild:before {
  content: "\eac5";
}
