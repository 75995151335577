.drawer-section {
    .text-overflow {
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: var(--font-size-14);
        }
    }
    .MuiDrawer-paper {
        border: none;
        margin-top: 120px;
        height: calc(100vh - 120px);
        display: flex;
        justify-content: space-between;
        width: 320px;
        @media (min-width: 1537px) {
            width: 352px;
        }
    }
    .MuiButtonBase-root {
        margin-bottom: 8px;
    }
    .MuiList-root {
        color: var(--darkblue);
        padding: 16px 0 0 0;
        @media (min-width: 1537px) {
            padding: 32px 0 0 0;
        }
    }
    .drawer-header {
        height: 120px;
    }
    .company-logo {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding: 0 20px 16px;
        @media (min-width: 1537px) {
            padding: 0 32px 32px;
        }
        .logo-txt {
            font-size: var(--font-size-11);
            letter-spacing: 0px;
            color: var(--black);
            position: relative;
            bottom: -8px;
            font-weight: var(--font-weight-medium);
        }
        .logo {
            height: 31px;
        }
    }
    .parent-list-menu {
        border-radius: 16px;
        margin-bottom: 8px;
        padding: 0 20px;
        text-align: left;
        font-size: var(--font-size-16);
        letter-spacing: 0px;
        @media (min-width: 1537px) {
            padding: 0 32px;
        }
        .MuiList-root {
            padding: 0;
        }

        .parent-menu-list {
            //background: var(--verypaleblue);
            border-radius: 16px;
            .MuiListItemIcon-root {
                color: var(--darkblue);
                margin-right: 16px;
            }
            .MuiTypography-root {
                font-size: var(--font-size-16);
                font-weight: var(--font-weight-semi-bold);
            }
            &:hover {
                border-radius: 16px;
                color: var(--darkblue);
                background: var(--verypaleblue);

                .MuiListItemIcon-root {
                    color: var(--darkblue);
                }
            }
            i {
                font-size: 24px;
            }
            &.Mui-selected {
                background: var(--darkblue);
                opacity: 1;
                color: var(--White);
                .MuiListItemIcon-root {
                    color: var(--White);
                }
                &:hover {
                    background: var(--darkblue);
                    color: var(--White);
                    .MuiListItemIcon-root {
                        color: var(--White);
                    }
                }
            }
        }
        .sub-first-item {
            padding: 14px 16px 14px 32px;
            border-radius: 16px;
            height: 40px;
            &.Mui-selected {
                color: var(--vividblue);
                background: none;
                font-weight: var(--font-weight-semi-bold);
                &:hover {
                    color: var(--vividblue);
                }
                .dot {
                    background-color: var(--vividblue);
                }
                .MuiTypography-root {
                    font-weight: var(--font-weight-semi-bold);
                }
            }
            .MuiListItemIcon-root {
                min-width: auto;
            }
            .MuiTypography-root {
                font-weight: 500;
            }
            &:hover {
                color: var(--darkblue);
                background: var(--verypaleblue);
            }
        }
        .dot {
            width: 8px;
            height: 8px;
            background-color: var(--slightlydesaturatedblue);
            border-radius: 50%;
            display: inline-block;
            margin-right: 24px;
        }

        .get-item {
            background: var(--darklimegreen);
            border-radius: 8px;
            width: 48px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--font-size-12);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: 0px;
            color: var(--White);
        }

        .active-sub-list {
            background: var(--verywhiteblue);
            border-radius: 16px;
            opacity: 1;
            .MuiButtonBase-root {
                &:hover {
                    background: none;

                    .MuiListItemIcon-root {
                        color: var(--vividblue);
                    }
                }
            }
            .MuiListItemIcon-root {
                min-width: auto;
            }
        }

        .nested-list-menu {
            height: 40px;
            border-radius: 16px;
            margin: 0 16px 8px 16px;
            padding: 8px 16px 8px 24px;
            &:hover {
                color: var(--darkblue);
                background: var(--verypaleblue);
            }
            .MuiTypography-root {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
            }
            .MuiListItemIcon-root {
                min-width: auto;
            }
            .dot {
                background-color: var(--moderatevioletcustom);
                margin-right: 16px;
            }
            &.Mui-selected {
                background: transparent
                    linear-gradient(99deg, var(--softviolet) 0%, var(--vividbluecustom) 53%, var(--darkblue) 100%) 0% 0%
                    no-repeat padding-box;
                color: var(--White);
                .dot {
                    background-color: var(--White);
                }
                .MuiTypography-root {
                    font-weight: 600;
                }
                &:hover {
                    background: transparent
                        linear-gradient(99deg, var(--softviolet) 0%, var(--vividbluecustom) 53%, var(--darkblue) 100%)
                        0% 0% no-repeat padding-box;
                    color: var(--White);
                    .MuiListItemIcon-root {
                        color: var(--White);
                    }
                }
            }
        }
    }
}
