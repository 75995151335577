.right-panel {
    .MuiDrawer-paper {
        border-radius: 16px;
        margin-right: 32px;
        height: auto;
        border: none;
        position: relative;
        margin-top: 32px;
        width: calc(100vw - 72px);
        @media (min-width: 900px) {
            position: fixed;
            margin-top: 156px;
            width: 380px;
            height: calc(100vh - 192px);
        }
        @media (min-width: 1200px) {
            width: 420px;
        }
        @media (min-width: 1537px) {
            margin-right: 80px;
            width: 480px;
        }
    }
    .code-generator {
        border: 1px solid var(--grayishblue);
        border-radius: 16px;
        box-shadow: none;
        margin-bottom: 8px;
        @media (min-width: 1537px) {
            margin-bottom: 20px;
        }
        .MuiCardHeader-root {
            padding: 8px 16px;
            border-bottom: 1px solid var(--lightgrayishblue);
            @media (min-width: 1537px) {
                padding: 12px 24px;
            }
        }
        .MuiTypography-root {
            font-size: var(--font-size-12);
            font-weight: var(--font-weight-medium);
            letter-spacing: 0px;
            color: var(--black);
            text-transform: uppercase;
        }
        .copy-btn {
            width: 88px;
            height: 32px;
            border-radius: 8px;
            font-size: var(--font-size-12);
            letter-spacing: 0px;
            font-weight: var(--font-weight-medium);
            color: var(--desaturateddarkblue);
            text-transform: capitalize;

            i {
                font-size: 20px;
                color: var(--desaturateddarkblue);
                margin-right: 8px;
            }
            &.Mui-disabled {
                color: rgba(0, 0, 0, 0.26);
                cursor: not-allowed;
                i {
                    color: rgba(0, 0, 0, 0.26);
                }
            }
        }
        .MuiCardContent-root {
            padding: 0px !important;
            span {
                background: var(--White) !important;
            }
        }
        code {
            max-height: calc(100vh - 680px);
            min-height: auto;
            font-size: var(--font-size-12) !important;
            background-color: var(--White);
            padding: 16px !important;
            letter-spacing: 0px;
            @media (min-width: 900px) {
                min-height: calc(100vh - 680px);
                max-height: calc(100vh - 680px);
            }
            @media (min-width: 1537px) {
                min-height: calc(100vh - 718px);
                max-height: calc(100vh - 718px);
            }
        }
    }
    .info {
        font-size: var(--font-size-12);
        letter-spacing: 0px;
        color: var(--black);
        @media (min-width: 1537px) {
            font-size: var(--font-size-13);
        }
    }
    .header {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-medium);
        letter-spacing: 0px;
    }
    .link-txt {
        text-align: left;
        text-decoration: underline !important;
        font-size: var(--font-size-13);
        letter-spacing: 0px;
        color: var(--vividblue);
    }
    .language-select {
        background: var(--White);
        border-radius: 16px;
        padding: 16px;
        @media (min-width: 1537px) {
            padding: 24px;
        }
        .custom-btn-group {
            background: var(--verywhiteblue);
            box-shadow: none;
            border-radius: 16px;
            margin-bottom: 32px;
            button {
                border-radius: 16px;
                background: var(--verywhiteblue);
                border: none;
                height: 48px;
                letter-spacing: 0px;
                color: var(--darkblue);
                font-weight: 600;
                font-size: var(--font-size-14);
                text-transform: unset;
                i {
                    font-size: 24px;
                    margin-right: 12px;
                }
                &.selected {
                    background: transparent
                        linear-gradient(103deg, var(--softviolet) 0%, var(--vividbluecustom) 53%, var(--darkblue) 100%);
                    color: var(--White);
                }
            }
        }
        .header-info {
            font-size: var(--font-size-16);
            font-weight: var(--font-weight-medium);
            letter-spacing: 0px;
            color: var(--black);
            margin-bottom: 12px;
        }
        .chips-box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            @media (min-width: 1537px) {
                margin-bottom: 28px;
            }
            .chips-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .MuiChip-avatarColorPrimary {
                    background: var(--White);
                }
                .label-name {
                    font-size: var(--font-size-12);
                    letter-spacing: 0px;
                    font-weight: var(--font-weight-medium);
                    color: var(--black);
                }
                .MuiChip-outlined {
                    width: 48px;
                    height: 48px;
                    justify-content: center;
                    display: flex;
                    margin-bottom: 8px;
                    border: 1px solid var(--White);
                    &:hover {
                        background: none;
                    }
                    &.MuiChip-outlinedPrimary {
                        border: 1px solid var(--vividblue);
                        background: var(--paleblue);
                    }
                    .MuiChip-avatar {
                        margin: auto;
                        width: auto;
                        height: auto;
                        img {
                            width: 32px;
                            height: 32px;
                            object-fit: scale-down;
                        }
                    }
                    .MuiChip-label {
                        display: none;
                    }
                }
            }
        }
    }
    .client-info {
        display: block;
        width: 100%;
        border-radius: 24px;
        &:hover {
            background: none;
        }
        .avatar {
            width: 72px;
            height: 72px;
            background: var(--White);
            img {
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .list-box {
            display: flex;
            align-items: center;
            width: 100%;
            @media (min-width: 900px) {
                justify-content: space-between;
            }
            .content-block {
                display: flex;

                .MuiListItemAvatar-root {
                    display: flex;
                    align-items: center;
                }
                @media (min-width: 900px) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .text-info {
                padding: 0 16px;
                text-transform: capitalize;
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                justify-content: space-between;
                flex-direction: column;

                .list-text-header {
                    font-size: var(--font-size-14);
                    letter-spacing: 0px;
                    color: var(--desaturatedbluecustom);
                    font-weight: var(--font-weight-medium);
                }
                .list-text-client {
                    font-size: var(--font-size-18);
                    font-weight: var(--font-weight-semi-bold);
                    letter-spacing: 0px;
                    color: var(--black);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .other-info {
                    display: flex;
                    align-items: center;

                    .sandbox {
                        padding: 4px 16px;
                        font-size: var(--font-size-12);
                        font-weight: var(--font-weight-semi-bold);
                        color: var(--White);
                        background: var(--moderatevioletcustom);
                        border-radius: 16px;
                        margin-right: 10px;
                    }
                    .production {
                        padding: 4px 12px;
                        font-size: var(--font-size-12);
                        font-weight: var(--font-weight-semi-bold);
                        background: var(--darklimegreen);
                        color: var(--White);
                        border-radius: 16px;
                        margin-right: 10px;
                    }
                    .client-id {
                        font-size: var(--font-size-14);
                        letter-spacing: 0px;
                        color: var(--black);
                        text-transform: lowercase;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .arrow-down {
            color: var(--black);
            position: relative;
            top: 4px;
        }
        .copy-btn {
            color: var(--black);
            margin: 0 16px;
            &.Mui-disabled {
                color: rgba(0, 0, 0, 0.26);
            }
            i {
                font-size: 20px;
            }
        }
    }
}
.custom-sandbox {
    .MuiPaper-root {
        box-shadow: 0px 3px 12px #00000029;
        border-radius: 16px;
        margin-top: 16px;
    }
    .custom-menu {
        padding: 0 16px;
        width: 100% !important;
        @media (min-width: 900px) {
            width: 342px !important;
        }
        @media (min-width: 1200px) {
            width: 382px !important;
        }
        @media (min-width: 1537px) {
            width: 432px !important;
        }
        &.MuiMenuItem-root:hover {
            background-color: transparent !important;
        }
        .MuiTouchRipple-root {
            display: none;
        }
        .MuiBox-root {
            align-items: start;
        }
        .hide-radio {
            display: none;
        }
        .MuiListItemAvatar-root {
            min-width: auto;
        }
        .MuiFormControlLabel-root {
            margin-bottom: 8px;
            margin-right: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 24px;
                position: relative;
                @media (min-width: 900px) {
                    padding-right: 0;
                }
            }
        }
        .copy-btn-tab {
            display: flex;
            position: absolute;
            right: -6px;
            top: -6px;
            svg {
                width: 18px;
                height: 18px;
            }
            @media (min-width: 900px) {
                display: none;
            }
        }
        &.Mui-selected {
            background: var(--lightgrayishbluehome);

            &:hover {
                background: var(--lightgrayishbluehome);
            }
            &.Mui-focusVisible {
                background: var(--lightgrayishbluehome);
            }
        }
        .custom-list {
            width: 100%;
            border-radius: 12px;
            .custom-list-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 16px 8px;
                margin-bottom: 16px;
                border-radius: 12px;
                border: 1px solid var(--White);
                &.selected {
                    background: var(--lightgrayishbluehome);
                    border: 1px solid var(--vividblue);
                    .info-box.sandbox {
                        background: var(--moderatevioletcustom);
                        color: var(--White);
                    }
                    .info-box.production {
                        background: var(--darklimegreen);
                        color: var(--White);
                    }
                }
            }
            .custom-list-item:last-child {
                margin-bottom: 0;
            }
            .avatar {
                width: 40px;
                height: 40px;
                object-fit: fill;
                object-position: center;
                background-color: var(--White);
                border: 1px solid var(--veryslightlydesaturatedblue);
            }
            .avatar-text {
                font-size: var(--font-size-18);
                font-weight: var(--font-weight-semi-bold);
                letter-spacing: 0px;
                color: var(--black);
                margin-left: 16px;
                margin-bottom: 4px;
            }
            .custom-radio {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-grid;
                .MuiRadio-root {
                    &.Mui-checked {
                        color: var(--darkblue);
                    }
                }
                .info-box {
                    font-size: var(--font-size-13);
                    margin: 0 16px 0 26px;
                    font-weight: var(--font-weight-medium);
                    letter-spacing: 0px;
                    justify-content: center;
                    border-radius: 16px;
                    display: inline-flex;
                    align-items: center;
                    padding: 4px 16px;
                    &.sandbox {
                        background: var(--paleviolet);
                        color: var(--moderatevioletcustom);
                    }
                    &.production {
                        background: var(--lightgrayishlimegreen);
                        color: var(--darklimegreen);
                    }
                }

                .radio-txt {
                    font-size: var(--font-size-13);
                    letter-spacing: 0px;
                    color: var(--black);
                    text-transform: lowercase;
                }
            }
        }
    }
}
